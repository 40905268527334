import { format } from 'date-fns';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  CurtainCommentActionPayload,
  DeleteCommentRequest,
  UpdateCommentRequest,
} from 'curtain/interfaces/CurtainCommentActionPayload';
import { CurtainGetOtherTopicsActionPayload } from 'curtain/interfaces/CurtainGetOtherTopicsActionPayload';
import { IChannel } from 'curtain/interfaces/IChannel';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';
import { IGetChannelsRequest } from 'curtain/interfaces/IGetChannelsRequest';
import { IGetTopicsRequest } from 'curtain/interfaces/IGetTopicsRequest';
import { NotifyEntity } from 'curtain/interfaces/NotifyEntity';

import {
  ChangeTopicPriorityRequest,
  CreateTopicRequest,
  UpdateTopicRequest,
} from 'curtain/interfaces/CreateTopicRequest';

import {
  curtainActions,
  setCurtainComments,
  setNumberOfUnreadTopics,
  setOtherTopics,
  setSingleCurtainComment,
  setTopics,
} from 'curtain/store/curtainActions';

import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';
import { Action } from 'shared/interface/Action';
import { CurtainComment } from 'shared/interface/CurtainComment';
import { FileUploadResponse } from 'shared/interface/serverResponses/FileUploadResponse';
import { UserRole } from 'shared/interface/UserRole';

import { Order } from '../../order/interfaces/Order';

function* curtainGetOtherTopicsSaga(
  action: Action<CurtainGetOtherTopicsActionPayload>
) {
  try {
    const response: ICurtainTopic[] = yield call(
      ApiService.get,
      `/api/collaboration/topics/other?orderId=${action.payload?.orderId}`
    );

    // yield put(setTopics(response));
    yield put(setOtherTopics(response));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* curtainGetCommentsSaga(action: Action<string>) {
  try {
    const response: CurtainComment[] = yield call(
      ApiService.get,
      `/api/collaboration/topics/${action.payload}/comments`
    );

    yield put(setCurtainComments(response));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* curtainResolveTopicSaga(action: Action<string>) {
  try {
    yield call(
      ApiService.put,
      `/api/collaboration/topics/${action.payload}/resolve`
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* curtainUnresolveTopicSaga(action: Action<string>) {
  try {
    yield call(
      ApiService.put,
      `/api/collaboration/topics/${action.payload}/unresolve`
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* curtainAddCommentSaga(action: Action<CurtainCommentActionPayload>) {
  try {
    const { data, topicID, userData } = action.payload!;
    const commentID: string = yield call(
      ApiService.post,
      `/api/collaboration/topics/${topicID}/comments`,
      {
        ...data,
        attachments: data.attachments!.map((att) => ({
          fileId: att.id,
          private: att.private,
        })),
      }
    );

    const commentObject: CurtainComment = {
      id: commentID,
      userId: userData.id,
      content: data.content,
      createdOnUtc: new Date().toISOString(),
      attachments: data.attachments
        ? data.attachments.map((att) => ({
            ...att,
            private: false,
            fileId: att.id,
          }))
        : [],
      user: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        imageUrl: userData.imageUrl ? userData.imageUrl : '',
        roles: [(userData.roles[0] as UserRole).description],
      },
      ...(data.ackId && { acknowledgementId: data.ackId }),
      changeRequested: data.changeRequested ?? false,
      isRead: true,
    };

    yield put(setSingleCurtainComment(commentObject));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* uploadFiles(action: Action<File[]>) {
  try {
    const uploadFilesPromises = [];

    for (let i = 0; i < action.payload!.length; i += 1) {
      const data = new FormData();
      data.append('file', action.payload![i]);

      uploadFilesPromises.push(ApiService.post(`/api/storage/files`, data));
    }

    const uploadedFiles: FileUploadResponse[] = yield Promise.all(
      uploadFilesPromises
    ).then((files) => {
      return files;
    });

    if (action.onSuccess) {
      yield call(action.onSuccess, uploadedFiles);

      if (action.loading) {
        yield call(action.loading!, false);
      }
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* curtainCreateTopicSaga(action: Action<CreateTopicRequest>) {
  try {
    const topicId: string = yield call(
      ApiService.post,
      `/api/collaboration/topics`,
      action.payload
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, topicId);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getChannelsSaga(action: Action<IGetChannelsRequest>) {
  try {
    const channels: IChannel[] = yield call(
      ApiService.get,
      `/api/collaboration/channels?orderId=${action.payload?.orderId}&type=${action.payload?.type}`
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, channels);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* curtainGetTopicsByChannelSaga(action: Action<IGetTopicsRequest>) {
  try {
    const topics: ICurtainTopic[] = yield call(
      ApiService.get,
      `/api/collaboration/topics?orderId=${action.payload?.orderId}&channelId=${action.payload?.channelId}`
    );

    yield put(setTopics(topics));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* curtainGetTopicsByEntitySaga(
  action: Action<{ orderId: string; entityId: string }>
) {
  try {
    const topics: ICurtainTopic[] = yield call(
      ApiService.get,
      `/api/collaboration/topics/entities?orderId=${
        action.payload?.orderId ?? ''
      }&entityId=${action.payload?.entityId ?? ''}`
    );

    yield put(setTopics(topics));

    if (action.onSuccess) {
      yield call(action.onSuccess, topics);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* curtainChangeTopicPrioritySaga(
  action: Action<ChangeTopicPriorityRequest>
) {
  try {
    yield call(
      ApiService.put,
      `/api/collaboration/topics/${action.payload?.topicId}/priority`,
      {
        priority: action.payload?.priority,
      }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getNotSentTopics(action: Action<string>) {
  try {
    const queryParams = new URLSearchParams();

    queryParams.append('orderId', action.payload!);

    const notifyEntities: { notSentChannelResponses: NotifyEntity[] } =
      yield call(ApiService.get, `/api/collaboration/topics/not-sent`, {
        params: queryParams,
      });

    if (action.onSuccess) {
      yield call(action.onSuccess, notifyEntities.notSentChannelResponses);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* notifyCollaborators(action: Action<string>) {
  try {
    yield call(
      ApiService.put,
      `/api/collaboration/topics/notify-collaborators`,
      {
        orderId: action.payload!,
      }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* downloadCollaboration(action: Action<Order>) {
  const order = action.payload!;
  try {
    const response: string = yield call(
      ApiService.get,
      `api/collaboration/topics/export`,
      {
        params: { orderId: order!.id! },
      }
    );

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(
      new Blob([response], { type: 'text/plain' })
    );

    const fileName = format(new Date(), 'MMddyyyy');

    a.download = `${order.serialNumber}_${order.job?.name}_${
      order.status!.name
    }_${fileName}.csv`;

    a.click();

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* markTopicAsRead(action: Action<string>) {
  try {
    yield call(
      ApiService.put,
      `/api/collaboration/topics/${action.payload!}/mark-as-read`,
      {}
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getNumberOfUnreadTopics(action: Action<string>) {
  try {
    const queryParams = new URLSearchParams();
    queryParams.append('orderId', action.payload!);

    const count: number = yield call(
      ApiService.get,
      `/api/collaboration/topics/number-of-unread`,
      {
        params: queryParams,
      }
    );

    yield put(setNumberOfUnreadTopics(count));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateTopic(action: Action<UpdateTopicRequest>) {
  try {
    yield call(ApiService.put, `/api/collaboration/topics`, action.payload);

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* deleteTopic(action: Action<string>) {
  try {
    yield call(
      ApiService.delete,
      `/api/collaboration/topics/${action.payload!}`
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateComment(action: Action<UpdateCommentRequest>) {
  try {
    const { topicId } = action.payload as UpdateCommentRequest;
    yield call(
      ApiService.put,
      `/api/collaboration/topics/${topicId}/comments`,
      {
        ...action.payload,
        attachments: action.payload!.attachments!.map((att) => ({
          fileId: att.id,
        })),
      }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* deleteComment(action: Action<DeleteCommentRequest>) {
  try {
    const { topicId, commentId } = action.payload as DeleteCommentRequest;

    yield call(
      ApiService.delete,
      `/api/collaboration/topics/${topicId}/comments/${commentId}`
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* curtainSagas() {
  yield takeEvery(curtainActions.GET_OTHER_TOPICS, curtainGetOtherTopicsSaga);
  yield takeEvery(curtainActions.RESOLVE_TOPIC, curtainResolveTopicSaga);
  yield takeEvery(curtainActions.UNRESOLVE_TOPIC, curtainUnresolveTopicSaga);
  yield takeEvery(curtainActions.ADD_COMMENT, curtainAddCommentSaga);
  yield takeEvery(curtainActions.GET_COMMENTS, curtainGetCommentsSaga);
  yield takeEvery(curtainActions.UPLOAD_FILE, uploadFiles);
  yield takeEvery(curtainActions.CREATE_TOPIC, curtainCreateTopicSaga);
  yield takeEvery(curtainActions.GET_CHANNELS, getChannelsSaga);
  yield takeEvery(
    curtainActions.GET_TOPICS_BY_CHANNEL,
    curtainGetTopicsByChannelSaga
  );
  yield takeEvery(
    curtainActions.GET_TOPICS_BY_ENTITY,
    curtainGetTopicsByEntitySaga
  );
  yield takeEvery(
    curtainActions.CHANGE_TOPIC_PRIORITY,
    curtainChangeTopicPrioritySaga
  );
  yield takeEvery(curtainActions.GET_NOT_SENT_TOPICS, getNotSentTopics);
  yield takeEvery(curtainActions.NOTIFY_COLLABORTORS, notifyCollaborators);
  yield takeEvery(curtainActions.DOWNLOAD_COLLABORATION, downloadCollaboration);
  yield takeEvery(curtainActions.MARK_TOPIC_READ, markTopicAsRead);
  yield takeEvery(
    curtainActions.GET_NUMBER_OF_UNREAD_TOPICS,
    getNumberOfUnreadTopics
  );
  yield takeEvery(curtainActions.UPDATE_TOPIC, updateTopic);
  yield takeEvery(curtainActions.DELETE_TOPIC, deleteTopic);
  yield takeEvery(curtainActions.UPDATE_COMMENT, updateComment);
  yield takeEvery(curtainActions.DELETE_COMMENT, deleteComment);
}
