import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderLineItemSM } from 'order/wizard/orderStyles/interface/LineItem';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import LineItemCarousel from 'order/wizard/orderLineItems/LineItemCarousel/LineItemCarousel';
import { useAdditionalFields } from 'shared/hooks/useAdditionalFields';

import {
  ConfigureLineItemSMRequest,
  IConfigureLineItemForm,
  IConfigureLineItemFormSM,
} from 'order/wizard/orderLineItems/interface/IConfigureLineItem';

import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Container } from 'shared/components/Container';
import { FormElement } from 'shared/components/FormElement';
import { H2, H4, H1, P, SPAN } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { lynch, silverChaliceTwo } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { usePopupMessage } from 'shared/hooks/usePopupMessage';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Wrapper } from 'shared/components/Wrapper';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import SVG from 'shared/components/SVG';

import {
  descriptionValidation,
  onlyDigitValidation,
  onlyWholeDigitValidation,
} from 'shared/validations/validations';

import { useIsStepDirty } from 'shared/hooks/useIsStepDirty';
import { getOrderLineItems } from 'order/store/orderActions';
import {
  clearLineItemsReducer,
  getLineItemSMDefaultData,
  getOrderLineItemSMData,
  setCurrentLineItem,
  getLineItemMetaByWHD,
  setLineItemDefaultData,
  setLineItemMetaByWHD,
  setOrderLineItemData,
  saveLineItemSM,
  updateLineItemSM,
  setIsLineItemsStepDirty,
  reorderLineItemModifications,
} from '../store/orderLineItemsActions';

import {
  ILineItemDefaultDataSM,
  ILineItemSize,
} from '../interface/ILineItemData';

import { OrderLineItemModalParams } from '../interface/OrderLineItemModalParams';
import FormError from '../../../../shared/components/FormError';
import { useHasPermissions } from '../../../../shared/hooks/useHasPermissions';
import { userPermissionsValues } from '../../../../shared/enum/userPermissionsEnum';
import UtilService from '../../../../shared/services/util.service';
import LineItemModifications from './LineItemModifications';
import { IModification } from './Modifications/interface/IModification';

interface MainContentContainerProps {
  interactionDisabled: boolean;
}

const MainContentContainer = styled(Container)<MainContentContainerProps>`
  min-height: calc(100vh - 145px);

  ${({ interactionDisabled }) => interactionDisabled && 'pointer-events: none;'}
`;

const ContainerLeft = styled(Wrapper)`
  width: 460px;
  position: sticky;
  top: 72px;
`;

const ContainerRight = styled(Wrapper)``;

const CustomHeadingTwo = styled(H2)`
  font-size: 24px;
  color: ${({ theme }) => theme.nevada};
  font-weight: 600;
`;

const CustomHeadingOne = styled(H1)`
  font-size: 40px;
  font-weight: 600;
`;

const AdditionalInformation = styled(Wrapper)`
  border: 1px dashed ${({ theme }) => theme.silverSandTwo};
  padding: 16px;
`;

const StyledP = styled(P)`
  flex: 1;
`;

export const StyledLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.nevada};

  &.disabled {
    opacity: 0.5;
  }
`;

const StyledH4 = styled(H4)`
  width: 100%;
  line-height: 20px;
  padding: 14px 0px;
`;

export const ConfigureSalesMaterialLineItem = () => {
  const columnWidth = 171;
  const dispatch = useAppDispatch();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const { onPageLoad, PopupModal } = usePopupMessage();

  const history = useHistory();
  const location = useLocation();

  const methods = useForm<IConfigureLineItemForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'productLineId',
    'lineItemId',
    'catalogLineItemId',
    'replacementLineItemId',
  ]);

  const [editMode, setEditMode] = useState(queryFields?.lineItemId ?? false);

  const [replacementMode, setReplacementMode] = useState(false);

  const [configureLineItemScrollRef, setConfigureLineItemScrollRef] =
    useState<Scrollbars | null>(null);

  const [replaceModalOpened, setReplaceModalOpened] = useState(false);

  const [zeroPriceModalOpened, setZeroPriceModalOpened] = useState(false);

  const { orderId } = useParams<OrderPageParams>();

  const numberOfOrderLineItems = useSelector((state: RootState) => {
    if (state.orderReducer.lineItems) {
      return state.orderReducer.lineItems.length + 1;
    }

    return 0;
  });

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const lineItemDefaultData = useSelector(
    (state: RootState) =>
      state.orderLineItemsReducer.lineItemDefaultData as ILineItemDefaultDataSM
  );

  const orderLineItemData = useSelector(
    (state: RootState) => state.orderLineItemsReducer.orderLineItemData
  );

  const lineItemMeta = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemMeta
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const customProductLine = useSelector((state: RootState) =>
    state.sharedReducer.productLines?.find(
      (productLine) => productLine.name === ProductLineEnums.PRODUCT_LINE_CUSTOM
    )
  );

  const deletedLineItemMods = useSelector(
    (state: RootState) =>
      state.orderLineItemsReducer.deletedLineItemModifications
  );

  const lineItemMods = useSelector(
    (state: RootState) => state.orderLineItemsReducer.lineItemModifications
  );

  const [lineItemDataLoading, setLineItemDataLoading] = useState(false);
  const [lineItemMetaLoading, setLineItemMetaLoading] = useState(false);

  const [lineItemDataSavingOrUpdating, setLineItemDataSavingOrUpdating] =
    useState(false);

  const [lineItemImage, setLineItemImage] = useState<ILineItemSize | null>(
    null
  );

  const [isModalClosed, setIsModalClosed] = useState(false);

  const attachmentSectionRef = useRef<HTMLDivElement>(null);

  const navigateToAttachments = () => {
    if (location.state?.navigateAttachments) {
      attachmentSectionRef.current?.scrollIntoView();
      history.replace(
        {
          pathname: location.pathname,
          search: location.search,
        },
        UtilService.omit(location.state, ['navigateAttachments'])
      );
    }
  };

  const SPEC_MODIFICATIONID = 'e3879d3a-c63b-46f7-afd2-ee6464b05bdb';

  const { onLineItemLoad, FormModal, answers } = useAdditionalFields({
    onCloseCallBack: () => {
      navigateToAttachments();
      setIsModalClosed(true);
    },
  });

  const addSpecModification = (note: string, additionalNote: string) => {
    dispatch(setIsLineItemsStepDirty(true));
    let emptyMod = {} as IModification;
    emptyMod = {
      ...emptyMod,
      id: uuidv4(),
      name: 'info',
      quantity: 1,
      price: 0,
      description: '',
      note,
      additionalNote,
      perDrawing: false,
      perFactoryDrawing: false,
      imageUrl: '',
      completed: true,
      number: 1,
      catalogModificationId: SPEC_MODIFICATIONID,
    };
    const tempModifications = [...(lineItemMods ?? []), emptyMod];
    const mods = tempModifications
      .map((mod) => {
        return {
          ...mod,
          number:
            mod.catalogModificationId !== SPEC_MODIFICATIONID
              ? mod.number + 1
              : 1,
        };
      })
      .sort((a, b) => UtilService.sortBy(a.number, b.number));

    dispatch(reorderLineItemModifications(mods));
  };

  const mapLineItemDefaultDataToLineItemForm = (
    data: ILineItemDefaultDataSM,
    quantity?: string
  ) => {
    setLineItemImage({
      description: '',
      imageUrl: data.imageUrl,
    } as ILineItemSize);

    const defaultValues = {
      quantity: quantity ?? '1',
      clientLineItemNumber: '',
      description: '',
      acknowledgementName: '',
    } as IConfigureLineItemFormSM;

    Object.entries(defaultValues).forEach(([key, value]) => {
      methods.setValue(key as keyof IConfigureLineItemFormSM, value);
    });
  };

  const mapOrderLineItemDataToLineItemForm = (
    orderLineItem: OrderLineItemSM
  ) => {
    const savedValues = {
      quantity: orderLineItem.quantity.toString(),
      clientLineItemNumber: orderLineItem.clientLineItemNumber,
      listPrice: orderLineItem.unitPrice ?? '',
      description: orderLineItem.description ?? 'N/A',
      acknowledgementName: orderLineItem.acknowledgementName,
    } as IConfigureLineItemFormSM;

    if (!replacementMode) {
      setLineItemImage({
        description: '',
        imageUrl: orderLineItem.imageUrl,
      } as ILineItemSize);
    }

    Object.entries(savedValues).forEach(([key, value]) => {
      methods.setValue(key as keyof IConfigureLineItemFormSM, value);
    });
  };

  const populateDefaultLineItemData = (quantity?: string) => {
    if (lineItemDefaultData) {
      mapLineItemDefaultDataToLineItemForm(lineItemDefaultData, quantity);
    }
  };

  const populateOrderLineItemData = () => {
    if (orderLineItemData) {
      mapOrderLineItemDataToLineItemForm(orderLineItemData);
    }
  };

  const redirectToCatalog = () => {
    dispatch(setCurrentLineItem(null));
    dispatch(setLineItemDefaultData(null));
    dispatch(setOrderLineItemData(null));
    dispatch(setLineItemMetaByWHD(null));

    const params = new URLSearchParams(location.search);

    params.delete('catalogLineItemId');

    if (!queryFields?.replacementLineItemId) {
      params.delete('lineItemId');
    }

    history.push(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      UtilService.omit(location.state, ['navigateAttachments'])
    );
  };

  const closeModal = () => {
    dispatch(clearLineItemsReducer());

    history.replace(
      {
        pathname: location.pathname,
      },
      location.state
    );
  };

  const onLineItemChangedSuccessHandler = () => {
    setLineItemDataSavingOrUpdating(false);
    closeModal();
    dispatch(clearLineItemsReducer());
    dispatch(
      getOrderLineItems({
        orderId,
        isSalesMaterial: true,
        productLineId: customProductLine?.id ?? '',
      })
    );
  };

  const onLineItemSaveSuccessHandler = () => {
    onLineItemChangedSuccessHandler();

    toast.success('You have successfully added line item to order.');
  };

  const onLineItemUpdateSuccessHandler = () => {
    onLineItemChangedSuccessHandler();
    toast.success(
      `You have successfully ${
        replacementMode ? 'replaced' : 'updated'
      } line item.`
    );

    // scroll to top when update
    if (configureLineItemScrollRef) {
      configureLineItemScrollRef.scrollToTop();
    }
  };

  const onSubmit = (data: IConfigureLineItemFormSM) => {
    dispatch(setIsLineItemsStepDirty(false));
    setZeroPriceModalOpened(false);

    if (queryFields) {
      const preparedData = {
        ...data,
        ...(!editMode && {
          catalogLineItemId: queryFields.catalogLineItemId,
        }),
        ...(editMode &&
          !replacementMode && {
            orderLineItemId: queryFields.lineItemId,
          }),
        ...(replacementMode && {
          existingLineItemId: queryFields.lineItemId,
          catalogLineItemId: queryFields.catalogLineItemId,
        }),
        productLineId: queryFields.productLineId,
        orderId,
        quantity: +data.quantity,
        clientLineItemNumber: data.clientLineItemNumber
          ? +data.clientLineItemNumber
          : null,
        listPrice: +data.listPrice,
        acknowledgementName: data.acknowledgementName,
        modifications: lineItemMods,
      } as ConfigureLineItemSMRequest;

      if (editMode) {
        setLineItemDataSavingOrUpdating(true);
        dispatch(
          updateLineItemSM(
            preparedData,
            onLineItemUpdateSuccessHandler,
            setLineItemDataSavingOrUpdating
          )
        );
      } else {
        setLineItemDataSavingOrUpdating(true);
        dispatch(
          saveLineItemSM(
            preparedData,
            onLineItemSaveSuccessHandler,
            setLineItemDataSavingOrUpdating
          )
        );
      }
    }
  };

  const getLineItemDefaultDataErrorHandler = () => {
    setLineItemDataLoading(false);
  };

  const getCatalogLineItem = () => {
    if (queryFields) {
      setLineItemDataLoading(true);
      dispatch(
        getLineItemSMDefaultData(
          {
            lineItemId: queryFields.catalogLineItemId,
            productLineId: queryFields.productLineId,
          },
          setLineItemDataLoading,
          getLineItemDefaultDataErrorHandler
        )
      );
    }
  };

  const getOrderLineItem = () => {
    if (queryFields && customProductLine) {
      setLineItemDataLoading(true);
      dispatch(
        getOrderLineItemSMData(
          {
            orderId,
            orderLineItemId: queryFields.lineItemId,
            productLineId: customProductLine.id,
          },
          setLineItemDataLoading
        )
      );
    }
  };

  const getLineItemMetaByDimensions = () => {
    if (queryFields) {
      setLineItemMetaLoading(true);

      dispatch(
        getLineItemMetaByWHD(
          {
            depth: 0,
            height: 0,
            lineItemId: queryFields?.catalogLineItemId ?? '',
            productLineId: queryFields?.productLineId ?? '',
            width: 0,
          },
          setLineItemMetaLoading
        )
      );
    }
  };

  // ----------KEEP THE DATA--------------
  const onReplacementConfirmHandler = (close: () => void) => {
    setReplaceModalOpened(false);
    close();

    if (queryFields?.catalogLineItemId) {
      // trigger popups
      onPageLoad(queryFields.catalogLineItemId);

      onLineItemLoad(queryFields.catalogLineItemId);

      // load price
      getLineItemMetaByDimensions();
    }
  };

  // ----------LOAD CATALOG DATA--------------
  const onReplacementCancelHandler = () => {
    // load default data
    populateDefaultLineItemData(methods.getValues().quantity.toString());

    // load price
    getLineItemMetaByDimensions();

    // close replacement modal
    setReplaceModalOpened(false);

    if (queryFields && lineItemMeta) {
      // trigger popups
      onPageLoad(queryFields.catalogLineItemId);

      onLineItemLoad(queryFields.catalogLineItemId);
    }
  };

  useEffect(() => {
    if (queryFields) {
      setEditMode(!!queryFields.lineItemId);
      setReplacementMode(!!queryFields.replacementLineItemId);
    }

    getCatalogLineItem();

    if (queryFields?.lineItemId ?? false) {
      getOrderLineItem();
    }
  }, [queryFields]);

  useEffect(() => {
    if (queryFields && !replacementMode) {
      onPageLoad(queryFields.catalogLineItemId);
    }
  }, [queryFields?.catalogLineItemId]);

  useEffect(() => {
    if (order && editMode && orderLineItemData) {
      populateOrderLineItemData();
    }

    if (order && !editMode && lineItemDefaultData) {
      populateDefaultLineItemData();
    }
  }, [editMode, order, orderLineItemData, lineItemDefaultData]);

  // load price only when not in edit mode and default data loads
  useEffect(() => {
    if (!editMode && lineItemDefaultData) {
      getLineItemMetaByDimensions();
    }
    if (lineItemDefaultData) {
      methods.setValue(
        'acknowledgementName',
        `${lineItemDefaultData.code} ${UtilService.displayLineItemSizes({
          width: lineItemDefaultData.defaultWidth,
          height: lineItemDefaultData.defaultHeight,
          depth: lineItemDefaultData.defaultDepth,
        })}`
      );
    }
  }, [lineItemDefaultData]);

  useEffect(() => {
    if (
      (methods.formState.isDirty || !editMode || replacementMode) &&
      lineItemMeta
    ) {
      methods.setValue('listPrice', lineItemMeta.price);
    }
  }, [lineItemMeta]);

  useEffect(() => {
    if (answers.length > 0) {
      let note = '';
      let additionalNote = '';
      if (answers.length === 1) {
        note = answers[0].answer;
      }

      if (answers.length > 1) {
        note = answers[0].answer;
        additionalNote = answers[1].answer;
      }

      addSpecModification(note, additionalNote);
    }
  }, [answers]);

  useEffect(() => {
    if (
      queryFields?.replacementLineItemId &&
      orderLineItemData &&
      lineItemDefaultData &&
      orderLineItemData.catalogLineItemId !== queryFields.catalogLineItemId
    ) {
      setReplaceModalOpened(true);

      setLineItemImage({
        description: '',
        imageUrl: lineItemDefaultData.imageUrl,
      });
    }
  }, [queryFields, orderLineItemData, lineItemDefaultData]);

  useIsStepDirty({
    isDirty: methods.formState.isDirty,
    dirtySetter: setIsLineItemsStepDirty,
    dirtyGetter: (state: RootState) => state.orderLineItemsReducer.isStepDirty,
  });

  const shouldShowZeroPrice = () => {
    return (
      +methods.getValues().listPrice < 4 && !lineItemDefaultData?.zeroPrice
    );
  };
  useEffect(() => {
    if (
      queryFields &&
      !lineItemMods?.some(
        (m) => m?.catalogModificationId === SPEC_MODIFICATIONID
      ) &&
      !deletedLineItemMods.some(
        (m) => m?.catalogModificationId === SPEC_MODIFICATIONID
      ) &&
      !lineItemMods?.some((m) => m.replacedModId === SPEC_MODIFICATIONID) &&
      !methods.formState.isDirty &&
      !queryFields?.replacementLineItemId &&
      !isModalClosed &&
      canEdit
    ) {
      onLineItemLoad(queryFields?.catalogLineItemId);
    }
  }, [lineItemMods, lineItemMetaLoading]);

  return (
    <>
      <Wrapper heightOfScreen="height: calc(100vh - 144px);" width={96.8}>
        <ScrollbarsCustom
          ref={(el) => setConfigureLineItemScrollRef(el)}
          autoHide
          autoHideTimeout={500}
          autoHideDuration={300}
        >
          <FormProvider {...methods}>
            <MainContentContainer interactionDisabled={replaceModalOpened}>
              <Spacer h="56px" />

              <Wrapper flex>
                <ContainerLeft flex column>
                  <LineItemCarousel size={lineItemImage} />
                </ContainerLeft>

                <Spacer w="122px" />

                <ContainerRight flex column>
                  <CustomHeadingTwo>
                    {lineItemDefaultData?.code || 'N/A'}
                  </CustomHeadingTwo>

                  <CustomHeadingOne>
                    <Wrapper flex middle gap={15}>
                      {lineItemDefaultData?.description || 'N/A'}
                    </Wrapper>
                  </CustomHeadingOne>

                  <Spacer h="48px" />

                  {isUserCSR && (
                    <>
                      <FormElement flexGrow>
                        <StyledLabel>Custom Description</StyledLabel>
                        <Spacer h="6px" />
                        <Input
                          {...methods.register(
                            'description',
                            descriptionValidation()
                          )}
                          aria-invalid={
                            methods.formState.errors.description
                              ? 'true'
                              : 'false'
                          }
                          disabled={!canEdit}
                        />
                        <FormError
                          label="Custom Description"
                          error={methods.formState.errors.description}
                          validationSchema={descriptionValidation()}
                        />
                      </FormElement>
                      <Spacer h="24px" />
                    </>
                  )}

                  {isUserCSR && (
                    <>
                      <FormElement flexGrow>
                        <StyledLabel>
                          Custom Acknowledgement Description
                        </StyledLabel>
                        <Spacer h="6px" />
                        <Input
                          {...methods.register(
                            'acknowledgementName',
                            descriptionValidation()
                          )}
                          aria-invalid={
                            methods.formState.errors.acknowledgementName
                              ? 'true'
                              : 'false'
                          }
                          disabled={!canEdit}
                        />
                        <FormError
                          label="Custom Acknowledgement Description"
                          error={methods.formState.errors.acknowledgementName}
                          validationSchema={descriptionValidation()}
                        />
                      </FormElement>
                      <Spacer h="48px" />
                    </>
                  )}

                  <AdditionalInformation flex middle>
                    <SVG icon={<InfoIcon />} color={silverChaliceTwo} />
                    <Spacer w="18px" />
                    <StyledP>N/A</StyledP>
                  </AdditionalInformation>

                  <Spacer h="32px" />

                  <Wrapper flex alignStart>
                    <FormElement
                      flexGrow
                      maxWidth={columnWidth}
                      minWidth={columnWidth}
                    >
                      <StyledLabel>L#</StyledLabel>
                      <Spacer h="6px" />
                      <StyledH4>
                        {!lineItemDataLoading &&
                          queryFields?.lineItemId &&
                          orderLineItemData?.number}

                        {!lineItemDataLoading &&
                          !queryFields?.lineItemId &&
                          numberOfOrderLineItems}
                      </StyledH4>
                    </FormElement>

                    <Spacer w="24px" />

                    <FormElement flexGrow maxWidth={columnWidth}>
                      <StyledLabel>CL#</StyledLabel>
                      <Spacer h="6px" />
                      <Input
                        {...methods.register(
                          'clientLineItemNumber',
                          onlyDigitValidation()
                        )}
                        aria-invalid={
                          methods.formState.errors.clientLineItemNumber
                            ? 'true'
                            : 'false'
                        }
                        disabled={!canEdit}
                      />
                    </FormElement>

                    <Spacer w="24px" />

                    <FormElement flexGrow maxWidth={columnWidth}>
                      <StyledLabel>Qty</StyledLabel>
                      <Spacer h="6px" />
                      <Input
                        {...methods.register(
                          'quantity',
                          onlyWholeDigitValidation(
                            { required: true },
                            'Quantity'
                          )
                        )}
                        aria-invalid={
                          methods.formState.errors.quantity ? 'true' : 'false'
                        }
                        disabled={!canEdit}
                      />
                    </FormElement>
                  </Wrapper>

                  <FormElement flexGrow maxWidth={columnWidth}>
                    <StyledLabel>
                      <Wrapper flex middle>
                        Net Price
                        <Spacer w="15px" />
                        {lineItemMetaLoading && <Loader noSpacing size={16} />}
                      </Wrapper>
                    </StyledLabel>
                    <Spacer h="6px" />
                    <Input
                      {...methods.register(
                        'listPrice',
                        onlyDigitValidation({ required: true })
                      )}
                      aria-invalid={
                        methods.formState.errors.listPrice ? 'true' : 'false'
                      }
                      disabled={!canEdit}
                    />
                  </FormElement>
                </ContainerRight>
              </Wrapper>
              <div ref={attachmentSectionRef}>
                <LineItemModifications />
              </div>
            </MainContentContainer>
          </FormProvider>
        </ScrollbarsCustom>

        <OrderFooter>
          <Wrapper flex middle>
            <ButtonSecondary
              onClick={redirectToCatalog}
              type="button"
              disabled={!canEdit}
            >
              Back to Catalog
            </ButtonSecondary>

            {!editMode && (
              <Wrapper flexGrow flex justifyEnd>
                <ButtonPrimary
                  type="button"
                  disabled={lineItemDataSavingOrUpdating}
                  onClick={
                    shouldShowZeroPrice()
                      ? () => setZeroPriceModalOpened(true)
                      : methods.handleSubmit(onSubmit)
                  }
                >
                  Add
                  <Loader
                    hidden={!lineItemDataSavingOrUpdating}
                    insideButton
                    noSpacing
                    size={16}
                  />
                </ButtonPrimary>
              </Wrapper>
            )}

            {editMode && (
              <Wrapper flexGrow flex justifyEnd>
                <ButtonPrimary
                  type="button"
                  disabled={lineItemDataSavingOrUpdating || !canEdit}
                  onClick={
                    shouldShowZeroPrice()
                      ? () => setZeroPriceModalOpened(true)
                      : methods.handleSubmit(onSubmit)
                  }
                >
                  Save and Exit
                  <Loader
                    hidden={!lineItemDataSavingOrUpdating}
                    insideButton
                    noSpacing
                    size={16}
                  />
                </ButtonPrimary>
              </Wrapper>
            )}
          </Wrapper>
        </OrderFooter>
      </Wrapper>

      <ConfirmationModal
        opened={zeroPriceModalOpened}
        title="Zero Price"
        message="This item has no price."
        confirm={() => setZeroPriceModalOpened(false)}
        cancel={methods.handleSubmit(onSubmit)}
        cancelButtonText="Close"
        shouldNotRenderSubmitButton
      />

      <ConfirmationModal
        title="Replace"
        nested
        opened={replaceModalOpened}
        buttonText="Keep the Data"
        cancelButtonText="Load Catalog Data"
        className="background-scrollable"
        confirm={onReplacementConfirmHandler}
        cancel={onReplacementCancelHandler}
        additionalAction={closeModal}
      >
        <P>
          You are about to replace{' '}
          <SPAN color={lynch} fontWeight={600}>
            #{orderLineItemData?.number} {orderLineItemData?.code}
          </SPAN>{' '}
          with{' '}
          <SPAN color={lynch} fontWeight={600}>
            #{orderLineItemData?.number} {lineItemDefaultData?.code}
          </SPAN>
          .
        </P>

        <Spacer h="15px" />

        <P>
          You can{' '}
          <SPAN color={lynch} fontWeight={600}>
            keep the data
          </SPAN>{' '}
          or{' '}
          <SPAN color={lynch} fontWeight={600}>
            load the data
          </SPAN>{' '}
          for the line item that is selected from catalog.
        </P>
      </ConfirmationModal>

      {PopupModal}
      {FormModal}
      {lineItemDataLoading && (
        <LoaderFullScreen flex middle center top={0} bottom={71}>
          <Loader size={40} />
        </LoaderFullScreen>
      )}
    </>
  );
};
