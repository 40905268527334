export const black: string = '#000000';
export const white: string = '#FFFFFF';

// confirmed
export const catskillWhite: string = '#EBF0F6';
export const lynch: string = '#677D9E';
export const mineShaft: string = '#2A2A2A';
export const mineShaftVariation: string = '#2D2D2D';
export const mineShaftVariationTwo: string = '#353535';
export const mischka: string = '#E2E2EA';

export const nevada: string = '#6f7073';
export const osloGray: string = '#939598';
export const periwinkleGray: string = '#CED8E9';
export const pigeonPost: string = '#B2C1D8';
export const silverSand: string = '#C1C2C4';
export const silverSandTwo: string = '#C1C2C3';
export const wildSand: string = '#F4F4F4';
export const wildSandTwo: string = '#F5F5F5';
export const blackSqueeze: string = '#EBF0F7';
export const kashmirBlue: string = '#476898';
export const poloBlue: string = '#98B1D7';
export const linkWater: string = '#D6E6F5';
export const heather: string = '#C2CCDA';
export const alto: string = '#DBDBDB';
export const rockBlue: string = '#9DAEC9';
export const silverChalice: string = '#ACACAC';
export const silverChaliceTwo: string = '#6F7073';
export const chambray: string = '#3A5C8E';
export const silver: string = '#C5BFBF';
export const alabaster: string = '#f8f8f8';
export const anzac: string = '#E3AA53';
export const parchment: string = '#EFE4D2';
export const fern: string = '#66AC60';
export const highland: string = '#679E69';
export const saltpan: string = '#ECF4EB';
export const valencia: string = '#D64535';
export const fairPink: string = '#FFEEEE';
export const gray: string = '#878686';
export const manatee: string = '#959699';
export const altoTwo: string = '#DADADA';
export const altoThree: string = '#CFCFCF';
export const athensGray = '#F8F8FA';
export const abbey = '#505152';
export const zircon = '#FAFCFF';
export const gallery = '#EBEBEB';
export const hawkesBlue = '#D1E2FC';
export const mercury = '#E9E9E9';
export const botticelli = '#cfd9e6';
export const windowsBlue = '#2E6AAE';
export const brownishPink = '#A26768';
export const taupe = '#A18569';

export const yellow = '#FFFDD0';
