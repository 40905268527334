import styled from 'styled-components';
import {
  ChangeEvent,
  useEffect,
  useState,
  FC,
  ClipboardEvent,
  DragEvent,
} from 'react';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ReactComponent as AttachmentIcon } from 'assets/icons/attachment.svg';

import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';
import { SelectCommentAttachmentMenu } from 'curtain/components/SelectCommentAttachmentMenu';
import {
  addCurtainComment,
  getCurtainComments,
  getNumberOfUnreadTopics,
  setUpdatedComment,
  updateComment,
  uploadFiles,
} from 'curtain/store/curtainActions';

import AvatarCircle from 'shared/components/AvatarCircle';
import FormError from 'shared/components/FormError';
import Loader from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { ACKStatusEnum } from 'order/wizard/orderAcknowledgement/enums/ACKStatusEnum';
import { CommentAttachment } from 'shared/components/CommentAttachment';
import { FileUploadResponse } from 'shared/interface/serverResponses/FileUploadResponse';
import { Form } from 'shared/components/Form';
import { MenuWithDropdown } from 'shared/components/MenuWithDropdown';
import { Order } from 'order/interfaces/Order';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { topicCommentValidation } from 'shared/validations/validations';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import {
  ButtonTertiaryDark,
  ButtonTertiaryLight,
  ButtonAttachmentIcon,
} from 'shared/components/Button';
import { CurtainComment } from 'shared/interface/CurtainComment';
import { UpdateCommentRequest } from 'curtain/interfaces/CurtainCommentActionPayload';
import { IChannel } from 'curtain/interfaces/IChannel';
import { Mention, MentionsInput } from 'react-mentions';
import { useMentionInput } from 'curtain/hooks/useMentionInput';
import { ChannelTypeEnum } from 'curtain/enums/ChannelTypeEnum';
import {
  updateLineItemNoOfUnreadTopics,
  updateStyleNoOfUnreadTopics,
} from 'order/store/orderActions';
import mentions from '../mentions/mentions.module.css';

interface FormInputs {
  commentText: string;
  attachedFile: FileList;
}

const CurtainCommentContainer = styled.div`
  padding: 16px 20px 20px 16px;
  border-top: 1px solid ${({ theme }) => theme.alto};
`;

const IconContainer = styled.div`
  height: 100%;
  margin-right: 11px;
`;

const InputContainer = styled(Wrapper)`
  flex: 1;
  min-width: 100px;
`;

const CommentAttachmentsContainer = styled(Wrapper)`
  margin-bottom: 12px;
`;

interface CurtainCommentFormProps {
  attachmentsAdded: () => void;
}

export const CurtainCommentForm: FC<CurtainCommentFormProps> = ({
  attachmentsAdded,
}) => {
  const dispatch = useAppDispatch();

  const [commentFiles, setCommentFiles] = useState<File[] | null>(null);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [userIds, setUserIds] = useState<string[]>([]);

  const userData = useSelector((state: RootState) => state.authReducer.user);

  const { acknowledgementId, acknowledgementStatus, id } = useSelector(
    (state: RootState) => state.orderReducer.order ?? ({} as Order)
  );

  const curtainActiveTopic: ICurtainTopic = useSelector(
    (state: RootState) => state.curtainReducer.curtainActiveTopic
  );

  const activeChannel: IChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const updatedComment: CurtainComment | null = useSelector(
    (state: RootState) => state.curtainReducer.updatedComment
  );

  const orderLineItems = useSelector(
    (state: RootState) => state.orderReducer.lineItems
  );

  const styles = useSelector((state: RootState) => state.orderReducer.styles);

  const draftKey = `draft_${activeChannel?.id ?? '0'}_${curtainActiveTopic.id}`;

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, errors },
    control,
  } = useForm<FormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      commentText: '',
    },
  });

  const commentTextWatched = watch('commentText');

  const resetForm = () => {
    setCommentFiles(null);
    dispatch(setUpdatedComment(null));
    reset();
  };

  const loadNumberOfUnreadTopics = () => {
    dispatch(getNumberOfUnreadTopics(id ?? ''));

    if (activeChannel && activeChannel.entityId) {
      if (activeChannel.type === ChannelTypeEnum.STYLE) {
        const activeStyle = styles?.find(
          (style) => style.id === activeChannel.entityId
        );

        if (activeStyle && activeStyle.unreadTopicCount > 0) {
          dispatch(
            updateStyleNoOfUnreadTopics({
              id: activeChannel.entityId,
              topicCount: activeStyle.unreadTopicCount - 1,
            })
          );
        }
      } else {
        const activeLineItem = orderLineItems?.find(
          (lineItem) => lineItem.lineItemId === activeChannel.entityId
        );
        if (activeLineItem && activeLineItem.numberOfUnreadTopics > 0) {
          dispatch(
            updateLineItemNoOfUnreadTopics({
              lineItemId: activeChannel.entityId,
              topicCount: activeLineItem.numberOfUnreadTopics - 1,
            })
          );
        }
      }
    }
  };

  // when comment is saved
  const afterCommentSuccessfulSubmit = () => {
    setIsAddingComment(false);
    dispatch(setUpdatedComment(null));
    resetForm();
    loadNumberOfUnreadTopics();
  };

  // when comment save throw error
  const afterCommentFailedSubmit = () => {
    setIsAddingComment(false);
    dispatch(setUpdatedComment(null));
    toast.error('There was an error while trying to add a comment.');
  };

  const onUpdateCommentSuccess = () => {
    toast.success('Comment has been updated successfully');
    afterCommentSuccessfulSubmit();
    dispatch(
      getCurtainComments(
        curtainActiveTopic?.id,
        () => false,
        () => toast.error('Failed to fetch comments.')
      )
    );
  };

  const regex = /[^{}]+(?=})/g;

  const submitComment = (attachments?: FileUploadResponse[]) => {
    if (updatedComment != null) {
      const updateData: UpdateCommentRequest = {
        commentId: updatedComment.id,
        topicId: curtainActiveTopic.id,
        content: commentTextWatched.replace(regex, '').replace(/{}/g, ''),
        attachments: attachments != null ? attachments : [],
        ...((acknowledgementStatus ?? 0) ===
          ACKStatusEnum.CHANGES_REQUESTED && {
          ackId: acknowledgementId,
          changeRequested: true,
        }),
        userIds,
      };

      dispatch(
        updateComment(updateData, onUpdateCommentSuccess, () => {
          afterCommentFailedSubmit();
          toast.error('Error while saving the comment.');
        })
      );
    } else {
      const data = {
        content: commentTextWatched.replace(regex, '').replace(/{}/g, ''),
        attachments:
          attachments != null
            ? attachments.map((attachment) => {
                return { ...attachment, private: curtainActiveTopic.private };
              })
            : [],
        ...((acknowledgementStatus ?? 0) ===
          ACKStatusEnum.CHANGES_REQUESTED && {
          ackId: acknowledgementId,
          changeRequested: true,
        }),
        userIds,
        private: curtainActiveTopic.private,
      };

      if (userData) {
        dispatch(
          addCurtainComment(
            { data, topicID: curtainActiveTopic.id, userData },
            afterCommentSuccessfulSubmit,
            afterCommentFailedSubmit
          )
        );
      }
    }
  };

  const onSuccessfulAttachmentUpload = (attachments: FileUploadResponse[]) => {
    submitComment(attachments);
  };

  const onFailedAttachmentUpload = () => {
    toast.error('There was an error while trying to upload the attached file.');
    setIsAddingComment(false);
  };

  const onSubmit = () => {
    setIsAddingComment(true);
    window.sessionStorage.removeItem(draftKey);
    if (commentFiles) {
      dispatch(
        uploadFiles(
          commentFiles,
          onSuccessfulAttachmentUpload,
          onFailedAttachmentUpload
        )
      );
    } else {
      submitComment();
    }
  };

  const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files != null && files.length > 0) {
      const validatedFileList = UtilService.validateFiles(files);

      setCommentFiles((prevState) =>
        prevState ? [...prevState, ...validatedFileList] : validatedFileList
      );
    } else {
      toast.error("Couldn't use the selected file");
    }
  };

  const onDropboxFileSelectedHandler = (file: File) => {
    const newFiles: File[] = [];
    newFiles.push(file);

    setCommentFiles((prevState) =>
      prevState ? [...prevState, ...newFiles] : newFiles
    );
  };

  const deleteSingleAttachment = (index: number) => {
    setCommentFiles([
      ...commentFiles!.filter(
        (comment, commentIndex) => commentIndex !== index
      ),
    ]);
  };

  useEffect(() => {
    attachmentsAdded();
  }, [commentFiles]);

  useEffect(() => {
    const commentText = window.sessionStorage.getItem(draftKey);
    if (updatedComment) {
      setValue('commentText', updatedComment?.content ?? '', {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else if (commentText) {
      setValue('commentText', commentText, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [updatedComment]);

  const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    if (e.clipboardData && e.clipboardData.files.length) {
      const file = e.clipboardData.files[0];
      if (file && file.type.includes('image')) {
        setCommentFiles((prevState) => [...(prevState ?? []), file]);
      }
    }
  };

  const handleDrop = (e: DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    for (let i = 0; i < files.length; i += 1) {
      setCommentFiles((prevState) => [...(prevState ?? []), files[i]]);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
  };

  const getButtonLabel = () => {
    if (isAddingComment) {
      return updatedComment ? 'Saving' : 'Replying';
    }

    return updatedComment ? 'Save' : 'Reply';
  };

  const selectedUsers = useMentionInput();

  useEffect(() => {
    if (commentTextWatched) {
      window.sessionStorage.setItem(draftKey, commentTextWatched);
      if (commentTextWatched.match(regex)) {
        const ids = commentTextWatched.match(regex) as string[];
        setUserIds([...userIds, ...ids]);
      }
    } else {
      window.sessionStorage.removeItem(draftKey);
      setUserIds([]);
    }
  }, [commentTextWatched]);

  return (
    <CurtainCommentContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper flex>
          <IconContainer>
            {userData && <AvatarCircle size={28} user={userData} />}
          </IconContainer>

          <InputContainer>
            <Controller
              control={control}
              name="commentText"
              rules={topicCommentValidation(
                {
                  required: true,
                },
                'Comment'
              )}
              render={({ field: { onChange, ref, value } }) => (
                <MentionsInput
                  onPaste={handlePaste}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  inputRef={ref}
                  onChange={onChange}
                  value={value}
                  classNames={mentions}
                  aria-invalid={errors.commentText ? 'true' : 'false'}
                  placeholder="Add comment"
                >
                  <Mention
                    data={selectedUsers}
                    className={mentions.mentions__mention}
                    markup="@[__display__]{__id__}"
                  />
                </MentionsInput>
              )}
            />

            <FormError
              label="Comment"
              error={errors.commentText}
              validationSchema={topicCommentValidation(
                {
                  required: true,
                },
                'Comment'
              )}
            />

            <Spacer h="15px" />

            {commentFiles && commentFiles.length > 0 && (
              <CommentAttachmentsContainer>
                <ScrollbarsCustom
                  autoHide
                  autoHeight
                  autoHeightMax={200}
                  autoHideTimeout={500}
                  autoHideDuration={300}
                >
                  {commentFiles.map((file, index) => (
                    <CommentAttachment
                      key={`${file.size}--${file.name}`}
                      fileName={file.name}
                      fileType={file.type}
                      deleteAttachment={() => deleteSingleAttachment(index)}
                    />
                  ))}
                </ScrollbarsCustom>
              </CommentAttachmentsContainer>
            )}
          </InputContainer>
        </Wrapper>

        <Wrapper flex justifyEnd middle>
          <MenuWithDropdown
            position="top right"
            className="position-fixed"
            trigger={
              <ButtonAttachmentIcon type="button">
                <AttachmentIcon />
              </ButtonAttachmentIcon>
            }
          >
            {(close: () => void) => {
              return (
                <SelectCommentAttachmentMenu
                  onDropboxFileSelected={onDropboxFileSelectedHandler}
                  closeDropdown={close}
                  handleFileInput={handleFileInput}
                />
              );
            }}
          </MenuWithDropdown>

          <Spacer w="10px" />

          <ButtonTertiaryLight
            type="button"
            onClick={resetForm}
            disabled={isAddingComment}
          >
            Cancel
          </ButtonTertiaryLight>

          <Spacer w="10px" />

          <ButtonTertiaryDark
            type="submit"
            disabled={!isValid || isAddingComment}
          >
            {getButtonLabel()}
            <Loader
              hidden={!isAddingComment}
              insideButton
              noSpacing
              size={16}
            />
          </ButtonTertiaryDark>
        </Wrapper>
      </Form>
    </CurtainCommentContainer>
  );
};
